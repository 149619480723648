import React from "react";
import "./Home.css";
import NavbarButton from "../../units/NavbarButton/NavbarButton";
import VerticalContact from "../../individual/VerticalContact/VerticalContact";
import congressLogo from "../../../assets/Logo 4ºC.png";
import useDeviceWidth from "../../../custom-hooks/useDeviceWidth";
const Home = () => {
  const { isMobile } = useDeviceWidth();
  return (
    <div className="home__container" id="home">
      <img
        src={congressLogo}
        alt="congress-logo"
        className="home__congress-logo"
      />
      <p className="home__welcome-text">
        El Col·legi d'Economistes de Catalunya organitza el 4rt Congrés
        d'Economia i Empresa de Catalunya 2025, que porta el lema Cap a una
        economia més digital i sostenible. Participa en aquest fòrum transversal
        que agruparà reflexions al voltant de grans temes com la globalització,
        la macroeconomia, l’estructura empresarial, el capital humà, el sistema
        públic, la innovació, les finances, les infraestructures, la transició
        energètica, la sostenibilitat mediambiental i el futur de la professió
        d’economista.
      </p>
      <div className="home__call-buttons-wrapper">
        <NavbarButton
          backgroundColor={"blue"}
          text={"Call for papers"}
          home={"home-blue"}
        />
        <NavbarButton
          backgroundColor={"white"}
          text={"Presentació d'abstracts"}
          home={"home-white"}
        />
        <NavbarButton
          backgroundColor={"white"}
          text={"Instruccions"}
          home={"home-white"}
        />
      </div>
      {!isMobile && <VerticalContact />}
    </div>
  );
};

export default Home;
