import { useContext } from "react";
import { UserActivityContext } from "./context/UserActivity";
import Call from "./components/individual/Call/Call";

import Footer from "./components/individual/Footer/Footer";
import RoutesComponent from "./components/screens/Routes/Routes.jsx";

function App() {
  const { displayCall } = useContext(UserActivityContext);
  return (
    <>
      {displayCall && <Call />}

      <RoutesComponent />
      <Footer />
    </>
  );
}

export default App;
