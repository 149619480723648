import React, { useContext } from "react";
import "./Call.css";
import { IoMdClose } from "react-icons/io";
import { UserActivityContext } from "../../../context/UserActivity";

const Call = () => {
  const { setDisplayCall } = useContext(UserActivityContext);

  return (
    <div className="overlay display">
      <div className="call-container">
        <div className="call-wrapper">
          <IoMdClose
            className="call-close"
            onClick={() => {
              setDisplayCall(false);
            }}
          />
          <a
            href={require("../../../assets/callPDF/CallCAT.pdf")}
            download={"CallCAT.pdf"}
          >
            Call for Papers - Català
          </a>
          <a
            href={require("../../../assets/callPDF/CallCAST.pdf")}
            download={"CallCAST.pdf"}
          >
            Call for Papers - Castellano
          </a>

          <a
            href={require("../../../assets/callPDF/CallENG.pdf")}
            download={"CallENG.pdf"}
          >
            Call for Papers - English
          </a>
        </div>
      </div>
    </div>
  );
};

export default Call;
