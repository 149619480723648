export const organitzacioData = {
  organitzacio: [
    "Carles Puig de Travy, degà del Col·legi d'Economistes",
    "Àngels Fitó, vicedegana del Col·legi d'Economistes",
    "Daniel Quer, coordinador",
    "Albert Carreras",
    "Toni Detrell",
    "Ana Garcia ",
    "Ester Oliveras",
    "Anton Gasol",
    "Àngel Hermosilla",
    "Jaume Menéndez",
    "Maurici Olivé",
    "Carme Poveda",
  ],
  cientific: [
    "Àngel Hermosilla",
    "Jaume Menéndez",
    "Maurici Olivé",
    "Carme Poveda",
  ],
};
