import React, { useState } from "react";
import "./EixosDisplay.css";
import { EixosDisplayContainer } from "./EixosDisplayStyles";
import useOverflowHeight from "../../../custom-hooks/useOverflowHeight";

const EixosDisplay = ({
  borderLeft,
  borderTop,
  borderRight,
  borderBottom,
  id,
  title,
  expanded,
  onClick,
  list,
  listTitle,
}) => {
  const [displayRollUp, setDisplayRollUp] = useState(false);
  const overflowHeight = useOverflowHeight(id);
  const handleTitleClick = () => {
    onClick(); // Executa la funció onClick per canviar l'estat de l'expansió
    if (expanded) {
      setDisplayRollUp(true); // Si es recull l'eix, activa l'estat de recollida
    } else if (!expanded) {
      setDisplayRollUp(false);
    }
  };
  return (
    <EixosDisplayContainer
      borderBottom={borderBottom}
      borderTop={borderTop}
      borderLeft={borderLeft}
      borderRight={borderRight}
      overflowHeight={overflowHeight}
      showAnimation={expanded}
      id={id}
      hideAnimation={displayRollUp}
    >
      <div className="eixos-display__title" onClick={handleTitleClick}>
        <p>{title}</p>
      </div>

      <div className="eixos-display__info-container">
        <p className="eixos-display__info">
          <p
            style={{
              marginLeft: "35px",
              marginRight: "10px",
              lineHeight: "1.5rem",
            }}
          >
            {listTitle}
          </p>
          <ul>
            {list !== undefined &&
              list.map((item) => {
                return <li>{item}</li>;
              })}
          </ul>
        </p>
      </div>
    </EixosDisplayContainer>
  );
};

export default EixosDisplay;
