import React, { useState } from "react";
import "./Eixos.css";
import EixosDisplay from "../../individual/EixosDisplay/EixosDisplay";
import SectionHeader from "../../individual/SectionHeader/SectionHeader";
import { eixosData } from "../../../data/eixosData";
import useDeviceWidth from "../../../custom-hooks/useDeviceWidth";
const Eixos = () => {
  const [expandedEixId, setExpandedEixId] = useState(null);
  const { isMobile } = useDeviceWidth();
  const handleEixClick = (eixId) => {
    if (expandedEixId === eixId) {
      setExpandedEixId(null); // Recull l'eix si ja està desplegat
    } else {
      setExpandedEixId(eixId); // Desplega l'eix si no està desplegat
    }
  };

  return (
    <div className="eixos__container" id="eixos">
      <SectionHeader title={"Eixos"} />
      <div className="eixos__items-wrapper-wrapper">
        <div className="eixos__items-wrapper" style={{ paddingLeft: "125px" }}>
          <EixosDisplay
            borderLeft={"1px"}
            borderRight={"0.5px"}
            borderTop={"1px"}
            borderBottom={"1px"}
            id={"eixos1"}
            title={
              "1. L’economia catalana dins el nou marc geoestratègic global"
            }
            listTitle={
              "Presidit per Joan Tugores, catedràtic d'Economia de la UB"
            }
            expanded={expandedEixId === "eixos1"}
            onClick={() => handleEixClick("eixos1")}
            list={eixosData.eix1}
          />
          <EixosDisplay
            borderLeft={"1px"}
            borderRight={"0.5px"}
            borderTop={"0px"}
            borderBottom={"1px"}
            id={"eixos2"}
            title={"2. Determinants del context macroeconòmic"}
            expanded={expandedEixId === "eixos2"}
            onClick={() => handleEixClick("eixos2")}
            list={eixosData.eix2}
            listTitle={
              "Presidit per Marta Curto, directora general d'Anàlisi Econòmica, Generalitat de Catalunya"
            }
          />
          <EixosDisplay
            borderLeft={"1px"}
            borderRight={"0.5px"}
            borderTop={"0px"}
            borderBottom={"1px"}
            id={"eixos3"}
            title={"3. La musculatura del sistema empresarial català"}
            expanded={expandedEixId === "eixos3"}
            onClick={() => handleEixClick("eixos3")}
            list={eixosData.eix3}
            listTitle={
              "Presidit per Salvador Alemany, president de Saba Infraestructuras i de la Comissió d’Economistes d’Empresa del Col·legi d’Economistes de Catalunya del CEC"
            }
          />
          <EixosDisplay
            borderLeft={"1px"}
            borderRight={"0.5px"}
            borderTop={"0px"}
            borderBottom={"1px"}
            id={"eixos4"}
            title={"4. El capital humà i el futur del treball"}
            expanded={expandedEixId === "eixos4"}
            onClick={() => handleEixClick("eixos4")}
            list={eixosData.eix4}
            listTitle={
              "Presidit per Joan Torrent, catedràtic d'Economia, Estudis d'Economia i Empresa de la UOC"
            }
          />
          <EixosDisplay
            borderLeft={"1px"}
            borderRight={"0.5px"}
            borderTop={"0px"}
            borderBottom={isMobile ? "0px" : "1px"}
            id={"eixos5"}
            title={"5. Sostenibilitat de les polítiques públiques"}
            expanded={expandedEixId === "eixos5"}
            onClick={() => handleEixClick("eixos5")}
            list={eixosData.eix5}
            listTitle={
              "Presidit per Josep Maria Duran, professor de la UB i director de l'Institut d'Economia de Barcelona"
            }
          />
        </div>
        <div className="eixos__items-wrapper" style={{ paddingRight: "125px" }}>
          <EixosDisplay
            borderLeft={"1px"}
            borderRight={"0.5px"}
            borderTop={"1px"}
            borderBottom={"1px"}
            id={"eixos6"}
            expanded={expandedEixId === "eixos6"}
            onClick={() => handleEixClick("eixos6")}
            title={
              "6. El sistema de ciència i innovació i el repte de la transformació digital"
            }
            list={eixosData.eix6}
            listTitle={
              "Presidit per Xavier Ferràs, MBA, PhD., Professor d’Innovació i Director Executive MBAs ESADE"
            }
          />
          <EixosDisplay
            borderLeft={"1px"}
            borderRight={"0.5px"}
            borderTop={"0px"}
            borderBottom={"1px"}
            id={"eixos7"}
            title={"7. Finances per una transició sostenible"}
            expanded={expandedEixId === "eixos7"}
            onClick={() => handleEixClick("eixos7")}
            list={eixosData.eix7}
            listTitle={
              "Presidit per Josep Soler, conseller executiu d'EFPA España"
            }
          />
          <EixosDisplay
            borderLeft={"1px"}
            borderRight={"0.5px"}
            borderTop={"0px"}
            borderBottom={"1px"}
            id={"eixos8"}
            title={"8. Infraestructures i gestió sostenible del territori"}
            expanded={expandedEixId === "eixos8"}
            onClick={() => handleEixClick("eixos8")}
            list={eixosData.eix8}
            listTitle={
              "Presidit per Anna Matas, catedràtica d'Economia Aplicada de la UAB i codirectora de la línia Infraestructures i transport de l'IEB"
            }
          />
          <EixosDisplay
            borderLeft={"1px"}
            borderRight={"0.5px"}
            borderTop={"0px"}
            borderBottom={"1px"}
            id={"eixos9"}
            title={"9. Sostenibilitat mediambiental"}
            expanded={expandedEixId === "eixos9"}
            onClick={() => handleEixClick("eixos9")}
            list={eixosData.eix9}
            listTitle={
              "Presidit per Ana García, finance process manager de Gildemeister Beteiligungen i presidenta de la Comissió d'Economia i Sostenibilitat del CEC"
            }
          />
          <EixosDisplay
            borderLeft={"1px"}
            borderRight={"0.5px"}
            borderTop={"0px"}
            borderBottom={"1px"}
            id={"eixos10"}
            title={"10. La contribució dels professionals de l'economia"}
            expanded={expandedEixId === "eixos10"}
            onClick={() => handleEixClick("eixos10")}
            list={eixosData.eix10}
            listTitle={
              "Presidit per Xavier Subirats, coordinador de la Comisión de Sostenibilidad y Resiliencia, Consejo General de Economistas de España"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Eixos;
