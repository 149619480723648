import React, { useContext } from "react";
import { UserActivityContext } from "../../../context/UserActivity";
import "./NavbarItem.css";
import { HashLink } from "react-router-hash-link";
const NavbarItem = ({ text, color, cami, setDisplayHamburgerMenu }) => {
  const { setDisplayOrganization, setDisplayCientific } =
    useContext(UserActivityContext);

  return (
    <HashLink className="navbar-item__wrapper" smooth to={`/#${cami}`}>
      <span
        className={
          color !== "white"
            ? "navbar-item__text"
            : "navbar-item__text white-text"
        }
        onClick={() => {
          setDisplayOrganization(false);
          setDisplayCientific(false);
          setDisplayHamburgerMenu(false);
        }}
      >
        {text}
      </span>
    </HashLink>
  );
};

export default NavbarItem;
