import React from "react";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import "./VerticalContact.css";
const VerticalContact = () => {
  return (
    <div className="vertical-contact__container">
      <a
        href="https://www.instagram.com/economistescat/"
        target="_blank"
        rel="noreferrer"
      >
        <FaInstagram
          color="white"
          size={25}
          className="vertical-contact__item"
        />
      </a>
      <a
        href="https://www.facebook.com/economistesdeCatalunya/?locale=ca_ES"
        target="_blank"
        rel="noreferrer"
      >
        <FaFacebook
          color="white"
          size={25}
          className="vertical-contact__item"
        />
      </a>

      <a
        href="https://twitter.com/economistescat"
        target="_blank"
        rel="noreferrer"
      >
        <FaXTwitter
          color="white"
          size={25}
          className="vertical-contact__item"
        />
      </a>
      <FaLinkedin color="white" size={25} className="vertical-contact__item" />
    </div>
  );
};

export default VerticalContact;
