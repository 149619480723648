import React from "react";
import "./Footer.css";
import logo from "../../../assets/Logo_CEC_BN_BLANC.png";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer__container">
      <div className="footer__top-wrapper">
        <div className="footer__top-wrapper__left-wrapper">
          <img
            src={logo}
            alt="logo"
            className="footer__logo"
            onClick={() => {
              window.open(
                "https://www.coleconomistes.cat/Paginas/Ficha.aspx?IdMenu=A2238BD0-3048-4D9D-AB8C-C91C6FDFD475&Idioma=ca-ES",
                "_blank"
              );
            }}
          />
        </div>
        <div className="footer__top-wrapper__right-wrapper">
          <a
            href="https://www.instagram.com/economistes_cat/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__link"
          >
            <FaInstagram size={27} color="white" className="footer__icon" />
          </a>

          <a
            href="https://www.facebook.com/economistesdeCatalunya/?locale=ca_ES"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__link"
          >
            <FaFacebook size={27} color="white" className="footer__icon" />
          </a>

          <a
            href="https://twitter.com/economistescat"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__link"
          >
            <FaXTwitter size={27} color="white" className="footer__icon" />
          </a>

          <a
            href="https://www.linkedin.com/company/economistescat/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__link"
          >
            <FaLinkedin size={27} color="white" className="footer__icon" />
          </a>
        </div>
      </div>
      <div className="footer__bottom-wrapper">
        <p>Copyright © 2024. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
