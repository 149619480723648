import styled from "styled-components";

export const NormesItemContainerDisplaytext = styled.div`
  width: calc(100% - 400px);
  border-radius: 30px;
  background: #fff;
  box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  position: relative;
  overflow: ${(props) => (props.showAnimation ? "visible" : "hidden")};
  @media screen and (max-width: 795px) {
    width: calc(100% - 20px);
    padding-top: 40px;
  }
`;
