import React from "react";
import "./Normes.css";
import SectionHeader from "../../individual/SectionHeader/SectionHeader";
import NormesItem from "../../individual/NormesItem/NormesItem";
import { normesData } from "../../../data/normesData";

const Normes = () => {
  return (
    <div className="normes__container" id="instruccions">
      <SectionHeader
        text={
          "Les normes que s'aplicaran als abstracts i notificacions son les següents."
        }
        title={"Normes"}
      />
      <div className="normes__items-wrapper">
        <NormesItem
          number={"1"}
          title={"COMUNICACIONS"}
          text={normesData.primer}
          id={"comunicacions"}
        />
        <NormesItem
          number={"2"}
          title={"COMUNICACIONS BREUS"}
          text={normesData.segon}
          id={"comunicacionsBreus"}
        />
        <NormesItem
          number={"3"}
          title={"DRETS"}
          text={normesData.tercer}
          id={"drets"}
        />
      </div>
    </div>
  );
};

export default Normes;
