import React from "react";
import "./Contacte.css";
import SectionHeader from "../../individual/SectionHeader/SectionHeader";
import ContacteItem from "../../individual/ContacteItem/ContacteItem";
import { HiOutlineMailOpen } from "react-icons/hi";
import { HiOutlinePhone } from "react-icons/hi";
import { FaMapMarkerAlt } from "react-icons/fa";
import useDeviceWidth from "../../../custom-hooks/useDeviceWidth";
const Contacte = () => {
  const { isMobile } = useDeviceWidth();
  return (
    <div className="contacte__container" id="contacte">
      <SectionHeader title={"Contacte"} text={"Seguim connectats"} />
      <div className="contacte__left-wrapper">
        <ContacteItem
          icon={<HiOutlineMailOpen color="white" size={!isMobile ? 30 : 20} />}
          title="Correu Electrònic"
          subtitle={"secretaria@congres2025.coleconomistes.cat"}
          email={true}
          phone={false}
          localization={false}
        />
        <ContacteItem
          icon={<HiOutlinePhone color="white" size={!isMobile ? 30 : 20} />}
          title="Telèfon"
          subtitle={"934 16 16 04"}
          phone={true}
          email={false}
          localization={false}
        />
        <ContacteItem
          icon={<FaMapMarkerAlt color="white" size={!isMobile ? 30 : 20} />}
          title="Ubicació"
          subtitle={"Plaça de Gal·la Placídia, 32"}
          localization={true}
          email={false}
          phone={false}
        />
      </div>
      {/* <div className="contacte__right-wrapper"></div> */}
    </div>
  );
};

export default Contacte;
