export const normesData = {
  primer: {
    primer: {
      title: "• Abstract previ",
      p1: "Per tal de presentar una comunicació al Congrés serà indispensable lliurar prèviament un resum del contingut de la comunicació que es vol aportar. Aquest resum (en forma d’abstract ampliat) tindrà una extensió màxima de 500 paraules. En ell caldrà incloure el nom de l’eix al que es vol lliurar, el títol de la comunicació, els autors, una justificació del seu interès, els seus objectius i els resultats i conclusions assolides.",
      p2: "Es presentaran únicament a través de la plataforma Scipedia, accessible a través de la web del congrés www.congres2025.coleconomistes.cat. La data límit per a la presentació d’aquests abstracts serà el dia 30 d’abril de 2024",
    },
    segon: {
      title: "• Normes per als autors de les comunicacions",
      p: [
        "Les comunicacions tindran una extensió màxima de de 10.000 paraules, incloent taules, gràfics, bibliografia i notes al peu.",
        "Es presentaran únicament a través de la plataforma Scipedia, que serà accessible a través de la web del congrés www.congres2025.coleconomistes.cat. Pel que fa als criteris de lliurament, es presentaran en format Word, amb lletra Arial font mida 11, espaiat d’interlineat senzill (1.25), marges de 1 polzada i mida de paper A4. Un cop acceptats els abstracts, la data límit de la presentació de les comunicacions definitives serà el dia 31 d’octubre de 2024.",
        "Els títols aniran en negreta, mida 14, espai senzill, alineats al marge esquerra.",
        "Les cites bibliogràfiques hauran d’aparèixer entre parèntesis, contenint indicacions sobre l’autor o autors, l’any de publicació i la pàgina o pàgines a les que s’al·ludeix, per exemple: (Blake, 1992: 72-83), Blake (1992:22) o (Blake et al., 1992: 72-83).",
        "Les referències bibliogràfiques aniran al final del treball sota el títol “Referències Bibliogràfiques”, ordenades alfabèticament per autors i d’acord amb els següents formats per normes, articles, comunicacions a congressos i llibres respectivament:",
        "CANADIAN INSTITUTE OF CHARTERED ACCOUNTANTS, CICA (1990) Public Sector Accounting Statement num. 6 “Local Government Financial Statements, Objectives and General Principles” Toronto.",
        "BENJAMIN, J.J. i BRENNER, V.C. (1974) “Perceptions of Journal Quality” The Accounting Review, April, 99.360-362.",
        "NEEDLES, B.E. (1995) “A profile, Annotated Bibliography and Index of International Accounting Research: 1965-1990” Comunicació presentada en el 18è Congrés Anual de la European Accounting Association, maig, pp. 10-12.",
        "PINA, V., i TORRES, L. (1999) “Análisis de la Información Externa, Financiera y de Gestión de las Administraciones Públicas”, Instituto de Contabilidad y Auditoría de Cuentas, Madrid.",
        "A la comunicació s’inclourà un abstract curt d’un màxim de 150 paraules i també s’hi inclourà un màxim de 5 paraules clau",
      ],
    },
  },
  segon: [
    "Alternativament, els/les participants poden presentar una comunicació breu (per exemple, en forma de nota d’opinió o policy paper) sobre l’àmbit temàtic a què fa referència l’eix.",
    "Aquestes comunicacions breus tindran un format escrit i una extensió màxima de 2.000 paraules. En elles caldrà incloure el nom de l’eix temàtic al que es vol lliurar, com també el títol de la comunicació.",
    "Es presentaran en format Word, lletra Arial font mida 11, espaiat d’interlineat senzill (1.25), marges de 1 polzada, mida de paper A4.",
    "De la mateixa manera que per a les comunicacions, en el cas de les comunicacions breus també caldrà presentar prèviament un resum o abstract (amb el mateix format de les comunicacions), i la data límit per a la presentació de l’abstract serà el dia 30 d’abril de 2024.",
    "Un cop acceptats els abstracts, la data límit de la presentació definitiva de les comunicacions breus serà el dia 31 d’octubre de 2024.",
    "Els títols aniran en negreta, mida 14, espai senzill, alineats al marge esquerra.",
    "També hauran de presentar-se a través de la plataforma Scipedia, accessible a través de la web del congrés www.congres2025.coleconomistes.cat.",
    "Tant els abstracts previs com les comunicacions poden ser presentades en català, castellà, francès o anglès.",
  ],
  tercer: [
    "Caldrà que l’autor o autors certifiquin que l’aportació és original, propietat del que l’envia i que no ha estat publicada enlloc ni ho serà abans del congrés.",
    "El Col·legi d’Economistes de Catalunya es reserva el dret d’utilitzar les aportacions rebudes per elaborar conclusions de cada eix temàtic, editar-les en format digital o en format imprès, tant a les conclusions del congrés com a la Revista Econòmica de Catalunya.",
    "La publicació es farà amb llicència CC-BY.",
    "En el cas de que l’autor no desitgi que la seva aportació sigui publicada, ho haurà de fer constar de manera expressa. En aquest cas, la seva aportació serà utilitzada tan sols com a base per a l’elaboració de les conclusions de l’eix al qual s’adreci.",
  ],
};
