import React, { useContext } from "react";
import "./NavbarButton.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { UserActivityContext } from "../../../context/UserActivity";

const NavbarButton = ({ backgroundColor, text, home }) => {
  const { setDisplayCall } = useContext(UserActivityContext);
  const handleClickButton = () => {
    if (text === "Presentació d'abstracts") {
      window.open("https://www.scipedia.com/sj/4rceec");
    } else if (text === "Call for papers") {
      setDisplayCall(true);
    }
  };
  return (
    <div
      className={`navbarButtonContainer ${home} ${
        backgroundColor === "blue" ? "blue" : "white"
      }`}
      onClick={() => {
        handleClickButton();
      }}
      style={
        text === "Instruccions"
          ? { display: "flex", justifyContent: "center", alignItems: "center" }
          : {}
      }
    >
      {text === "Instruccions" && (
        <a
          href={require("../../../assets/scipediaTutorial.pptx")}
          download={"scipediaTutorial.pptx"}
          style={{ color: " #003a83" }}
        >
          <p className={text === "Scipedia" ? "scipedia-button" : ""}>
            {text} {text === "Scipedia" && <FaArrowRightLong />}
          </p>
        </a>
      )}
      {text !== "Instruccions" && (
        <p className={text === "Scipedia" ? "scipedia-button" : ""}>
          {text} {text === "Scipedia" && <FaArrowRightLong />}
        </p>
      )}
    </div>
  );
};

export default NavbarButton;
