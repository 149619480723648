import React, { createContext, useState } from "react";

// Crea el context
export const UserActivityContext = createContext();

// Crea el provider del context
export const UserActivityProvider = ({ children }) => {
  const [displayCall, setDisplayCall] = useState(false);
  const [displayOrganization, setDisplayOrganization] = useState(false);
  const [displayCientific, setDisplayCientific] = useState(false);
  return (
    <UserActivityContext.Provider
      value={{
        displayCall,
        setDisplayCall,
        displayOrganization,
        setDisplayOrganization,
        displayCientific,
        setDisplayCientific,
      }}
    >
      {children}
    </UserActivityContext.Provider>
  );
};
