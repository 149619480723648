import { useState, useEffect } from "react";

const useOverflowHeight = (id) => {
  const [overflowHeight, setOverflowHeight] = useState(null);

  useEffect(() => {
    const calculateOverflowHeight = () => {
      const element = document.getElementById(id);
      if (element) {
        const height = element.getBoundingClientRect();
        const scrollHeight = element.scrollHeight;
        const overflowHeightNumber = scrollHeight - height.height;
        setOverflowHeight(overflowHeightNumber);
      }
    };

    if (id) {
      calculateOverflowHeight();
      window.addEventListener("resize", calculateOverflowHeight);
      return () => {
        window.removeEventListener("resize", calculateOverflowHeight);
      };
    }
  }, [id]);

  return overflowHeight;
};

export default useOverflowHeight;
