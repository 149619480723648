import React, { useState } from "react";
import "./NormesItem.css";
import { FaCircleArrowDown } from "react-icons/fa6";
import { FaCircleArrowUp } from "react-icons/fa6";
import useOverflowHeight from "../../../custom-hooks/useOverflowHeight";
import { NormesItemContainerDisplaytext } from "./NormesItemStyled";
import useDeviceWidth from "../../../custom-hooks/useDeviceWidth";
const NormesItem = ({ number, title, text, id }) => {
  const [displayText, setDisplayText] = useState(false);
  const overflowHeight = useOverflowHeight(id);
  const [hideAnimation, setHideAnimation] = useState(null);
  const { isMobile } = useDeviceWidth();
  return (
    <NormesItemContainerDisplaytext
      showAnimation={displayText}
      hideAnimation={hideAnimation}
      overflowHeight={overflowHeight}
      id={id}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "60px",
        }}
      >
        <div className="normes-item__number">
          <p>{number}</p>
        </div>
        <p className="normes-item__title">{title}</p>
      </div>

      {displayText &&
        number !== "1" &&
        text.map((item) => {
          return (
            <p
              className={
                !displayText
                  ? "normes-item__text"
                  : "normes-item__text normes-item__text-padding-bottom"
              }
            >
              {item}
            </p>
          );
        })}
      {displayText && number === "1" && (
        <>
          {text.primer !== undefined && (
            <p
              className={
                !displayText
                  ? "normes-item__text"
                  : "normes-item__text normes-item__text-padding-bottom"
              }
              style={{ fontWeight: "bold" }}
            >
              {text.primer.title}
            </p>
          )}
          {text.primer !== undefined && (
            <>
              <p
                className={
                  !displayText
                    ? "normes-item__text"
                    : "normes-item__text normes-item__text-padding-bottom"
                }
              >
                {text.primer.p1}
              </p>

              <p
                className={
                  !displayText
                    ? "normes-item__text"
                    : "normes-item__text normes-item__text-padding-bottom"
                }
              >
                {text.primer.p2}
              </p>
            </>
          )}
          {text.segon !== undefined && (
            <p
              className={
                !displayText
                  ? "normes-item__text"
                  : "normes-item__text normes-item__text-padding-bottom"
              }
              style={{ fontWeight: "bold" }}
            >
              {text.segon.title}
            </p>
          )}
          {text.segon !== undefined &&
            text.segon.p.map((item) => {
              return (
                <p
                  className={
                    !displayText
                      ? "normes-item__text"
                      : "normes-item__text normes-item__text-padding-bottom"
                  }
                >
                  {item}
                </p>
              );
            })}
        </>
      )}
      {!displayText && (
        <FaCircleArrowDown
          className="normes-item__arrow"
          color="#003a83"
          size={!isMobile ? 30 : 20}
          onClick={() => {
            setDisplayText(!displayText);
            setHideAnimation(false);
          }}
        />
      )}
      {displayText && (
        <FaCircleArrowUp
          className="normes-item__arrow"
          color="#003a83"
          size={!isMobile ? 30 : 20}
          onClick={() => {
            setDisplayText(!displayText);
            setHideAnimation(true);
          }}
        />
      )}
    </NormesItemContainerDisplaytext>
  );
};

export default NormesItem;
