import { useState, useEffect } from "react";

const useDeviceWidth = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      // Espera breument abans de comprovar si és mòbil
      setTimeout(() => {
        setWidth(window.innerWidth);
        setIsMobile(window.innerWidth < 795); // Assumim que un dispositiu amb una amplada inferior a 795px és un dispositiu mòbil
      }, 100); // Mil·lisegons d'espera
    };

    window.addEventListener("resize", handleResize);

    // Eliminem l'esdeveniment de l'actualització quan es desmunta el component
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { width, isMobile };
};

export default useDeviceWidth;
