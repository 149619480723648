import React from "react";
import "./SectionHeader.css";
const SectionHeader = ({ title, text, halfWidth }) => {
  return (
    <div
      className={
        !halfWidth
          ? "section-header__container"
          : "section-header__container section-header__half-width"
      }
    >
      <div className="section-header__title">{title}</div>
    </div>
  );
};

export default SectionHeader;
