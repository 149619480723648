export const eixosData = {
  eix1: [
    "Globalització: governança econòmica supranacional i multilateralisme vs. aïllacionisme i proteccionisme",
    "Aplicació i desenvolupament dels acords internacionals bilaterals i multilaterals",
    "Els efectes de les noves tecnologies en el marc econòmic internacional: aplicació a les transaccions comercials i de serveis, fluxos d’inversions, ciberseguretat",
    "Les tensions als mercats de l’energia i les primeres matèries",
    "La fragmentació i resiliència de les cadenes globals de valor",
    "Factors de competitivitat del sistema productiu català i canvis als mercats internacionals",
  ],
  eix2: [
    "PIB i productivitat",
    "Impacte de la demografia en el creixement",
    "Dinàmiques dels intercanvis exteriors de l’economia catalana",
    "Endeutament privat, situació financera del sector privat i sensibilitat als tipus d’interès",
    "Política fiscal: ingressos i despesa pública",
    "Deute públic",
    "Les finances de la Generalitat i el sistema finançament autonòmic",
  ],
  eix3: [
    "Estructura empresarial a Catalunya",
    "El paper de les empreses multinacionals",
    "Pimes i autònoms davant del futur",
    "Especialització productiva en el context internacional",
    "Nous reptes productius: manufactures, turisme, agroalimentari, comerç, finances, esport",
    "Industria 4.0",
    "El repte logístic",
    "Emprenedoria i capital empresarial",
    "Màrqueting i comercialització",
    "Factors restrictius d’oferta",
    "Economia col·laborativa",
  ],
  eix4: [
    "Requeriments formatius",
    "El rol de la immigració",
    "Fugida de talent",
    "La qualitat dels llocs de treball",
    "Salari mínim",
    "Modalitats contractuals",
    "Teletreball, coworking i noves formes d’organització del treball",
    "Gig economy i l’impacte de les plataformes digitals",
    "Canvis a les relacions laborals",
    "El futur del treball en un món governat per la intel·ligència artificial",
  ],
  eix5: [
    "Polítiques de regulació dels mercats i l’oferta productiva",
    "Qualitat institucional i desenvolupament econòmic",
    "Dèficit fiscal i Balança fiscal amb l’Estat",
    "Els àmbits competencials: educació, sanitat i serveis socials",
    "Pobresa i desigualtat: tendències i polítiques",
    "Pensions",
    "Redistribució de la renda",
    "Habitatge",
  ],
  eix6: [
    "On som en recerca i innovació a Catalunya? Punts forts i punts febles",
    "L'impacte econòmic del sistema d'innovació",
    "Anàlisi comparada amb els millors sistemes d'innovació",
    "Polítiques de transferència i cooperació: relació acadèmia-indústria / sector públicsector privat",
    "Processos de connexió entre els agents del sistema d'innovació",
    "Estructura i impacte de les polítiques publiques de R+D+i",
    "Spin-offs",
    "Els reptes de la transformació digital empresarial",
    "L'emergència i l'impacte de les grans plataformes digitals",
    "Intel·ligència artificial i altres tecnologies emergents",
    "Transformació digital i polítiques públiques",
    "Nous models de negoci",
    "Implicacions sectorials: sector salut, sector financer, sector automoció, etc",
  ],
  eix7: [
    "Estructura del sector financer a Catalunya",
    "Tendències en els mercats de capitals (Equity i deute: Borsa, MAB, MARF, Mercats OTC, Plataformes)",
    "Tendències en capital risc, crowdfunding, crowdlending i altres plataformes i mecanismes de finançament alternatiu",
    "Tendències i canvis en la gestió d'actius (gestió activa/passiva/semipassiva, costos distribució/gestió)",
    "'Capital Markets Union', oportunitats per la desbancarització financera en el marc de la Unió Europea",
    "Finançament i inversions en el segment de les Pimes",
    "Reptes i canvis disruptius per el sector bancari i financer i impactes",
    "RevolucióDigital i Fintech: situació actual i principals tendències",
    "Tendències en l'assessorament financer, Financial Guidance, i Financial Literacy",
    "La nova directiva DEBRA",
  ],
  eix8: [
    "Ports, aeroports i sistema ferroviari",
    "Xarxa viaria: estructura i finançament",
    "Intermodalitat",
    "Els processos d’urbanització",
    "La gestió de la mobilitat",
    "Polígons industrials i sòl per a usos",
    "Les polítiques de gestió del sòl",
    "La gestió de l’aigua",
    "L’impacte de les inversions en energies renovables",
  ],
  eix9: [
    "Desenvolupament sostenible",
    "Territori i natura",
    "Transició energètica",
    "Economia circular i sistema productiu",
    "Contaminació i competitivitat",
  ],
  eix10: [
    "Nous marcs normatius (mercantils, fiscals, comptables...)",
    "Noves tendències en informació financera, informació de sostenibilitat, control de la gestió empresarial",
    "Impacte de les tecnologies emergents",
    "Sistemes d’informació, intel·ligència artificial",
    "Complexitats i oportunitats vinculades a la creixent internacionalització de l’activitat econòmica",
    "El compromís social (ètica, deontologia i RSC)",
    "El rol de les associacions i col·legis professionals",
    "La formació i habilitats requerides en els diferents camps de treball de l’economista",
  ],
};
