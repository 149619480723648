import React from "react";
import "./Organitzacio.css";
import Navbar from "../../individual/Navbar/Navbar";

const Organitzacio = ({ title, president, list }) => {
  return (
    <div className="organitzacio-apart__container">
      <Navbar />
      <div className="organitzacio-apart__header">
        {title !== undefined && <p>{title}</p>}
      </div>
      <div className="organitzacio-apart__wrapper">
        <div className="organitzacio-apart__wrapper-item wrapper-item-border">
          <p className="organitzacio-apart__wrapper-item__subtitle">
            {president !== undefined && president}
          </p>
          <p className="organitzacio-apart__wrapper-item__subtitle">MEMBRES:</p>
          <div className="organitzacio-apart__wrapper-item__list-container">
            {list !== undefined &&
              list.map((item, index) => <span key={index}>{item}</span>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organitzacio;
