import React from "react";
import "./ContacteItem.css";
const ContacteItem = ({
  icon,
  title,
  subtitle,
  email,
  phone,
  localization,
}) => {
  const handleClick = (email, phone, localization) => {
    console.log(email, phone, localization);
    if (email === true) {
      const mailtoLink = `mailto:${`secretaria@congres2025.coleconomistes.cat`}?subject=${`Informació Congrés 2025`}`;
      window.location.href = mailtoLink;
    } else if (phone === true) {
      window.location.href = `tel:${`934 16 16 04`}`;
    } else if (localization === true) {
      console.log("HEY");
      const establishmentName = "Col·legi d'Economistes de Catalunya";
      const streetAddress = "Plaça de Gal·la Placídia, 32";
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        establishmentName
      )}, ${encodeURIComponent(streetAddress)}`;
      window.open(googleMapsUrl, "_blank");
    }
  };
  return (
    <div
      className="contacte-item__container"
      onClick={() => {
        if (
          email !== undefined &&
          ((phone !== undefined) !== localization) !== undefined
        ) {
          handleClick(email, phone, localization);
        }
      }}
    >
      <div className="contacte-item__left-wrapper">
        <div className="contacte-item__icon-container">{icon}</div>
      </div>
      <div className="contacte-item__right-wrapper">
        <span className="contacte-item__title">{title}</span>
        <span className="contacte-item__subtitle">{subtitle}</span>
      </div>
    </div>
  );
};

export default ContacteItem;
